import React from 'react'
import Helmet from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import Link from 'gatsby-link'

import Button from '../components/styled/Button'
import Layout from '../components/Layout'
import Order from '../components/styled/Order'
import OrderProductList from '../components/OrderProductList'
import Price from '../components/Price'
import ShowDate from '../components/ShowDate'

import useOrders from '../hooks/useOrders'
import useOrdersActions from '../hooks/useOrdersActions'

const List = styled.ul`
    li {
        margin: 0;
    }
`

const ForDeliverList = styled.ul`
    margin: 0 0 1rem;

    li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: solid 1px ${props => props.theme.brandColor1};
        padding: 0.5rem 1rem;
        margin: 0;
    }

    p {
        margin: 0;
    }
`

const getStoreFromHash = hash =>
    hash
        .split('/')
        .filter(item => item !== '#')
        .join('')

const Content = ({ store }) => {
    const { forDeliver, orders, loading, error } = useOrders({
        store,
    })

    const { cancel, markAsComplete, markAsDelivered } = useOrdersActions({
        store,
    })

    if (error) {
        return <Order.EmptyState>{JSON.stringify(error)}</Order.EmptyState>
    }

    if (loading) {
        return <Order.EmptyState>Cargando...</Order.EmptyState>
    }

    return (
        <>
            {forDeliver.length ? (
                <>
                    <ForDeliverList>
                        {forDeliver.map((order, i) => {
                            return (
                                <li key={i}>
                                    <p>
                                        {order.name} - {order.phone}
                                    </p>
                                    <Button
                                        slim
                                        outline
                                        onClick={async () => {
                                            await markAsDelivered(order.id)
                                        }}
                                    >
                                        Marcar como entregado
                                    </Button>
                                </li>
                            )
                        })}
                    </ForDeliverList>
                </>
            ) : null}

            {orders.length ? (
                <Order.Wrapper>
                    {orders.map((order, i) => {
                        const date = order.createdAt.toDate()
                        return (
                            <Order.Item key={i}>
                                <Order.Header>
                                    <h3>
                                        <ShowDate date={date} type="time" /> -{' '}
                                        {order.name}
                                    </h3>
                                    <List>
                                        <li>
                                            Teléfono:{' '}
                                            <a href={`tel:${order.phone}`}>
                                                {order.phone}
                                            </a>
                                        </li>
                                        <li>
                                            Pago: {order.payment} {order.badge}
                                        </li>
                                    </List>
                                </Order.Header>

                                <OrderProductList items={order.items} />

                                <Order.Footer>
                                    <h4>
                                        Total: <Price>{order.total}</Price>
                                    </h4>

                                    <div>
                                        <Button
                                            style={{ marginRight: '1rem' }}
                                            outline
                                            onClick={async () => {
                                                await cancel(order.id)
                                            }}
                                        >
                                            Cancelar
                                        </Button>

                                        <Button
                                            onClick={async () => {
                                                await markAsComplete(order.id)
                                            }}
                                        >
                                            Marcar como listo
                                        </Button>

                                        {/* <Button
                                            onClick={async () => {
                                                await actions.markAsDelivered(
                                                    order.id
                                                )
                                            }}
                                        >
                                            Marcar como entregado
                                        </Button> */}
                                    </div>
                                </Order.Footer>
                            </Order.Item>
                        )
                    })}
                </Order.Wrapper>
            ) : (
                <Order.EmptyState>No hay ordernes pendientes</Order.EmptyState>
            )}
        </>
    )
}

const Menu = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    a {
        border-radius: 1rem;
        margin: 1rem;
        display: flex;
        align-items: center;
        padding: 1rem;
        border: solid 1px ${props => props.theme.gray};
    }

    img {
        margin: 0;
    }
`

const SelectStore = () => {
    const logos = useStaticQuery(
        graphql`
            query {
                smile: file(relativePath: { eq: "logo-smile.png" }) {
                    childImageSharp {
                        # Specify a fixed image and fragment.
                        # The default width is 400 pixels
                        fixed(width: 160) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
                emerson: file(relativePath: { eq: "logo-emerson.png" }) {
                    childImageSharp {
                        # Specify a fixed image and fragment.
                        # The default width is 400 pixels
                        fixed(width: 160) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        `
    )

    return (
        <Menu>
            <Link to="/ordenes/#/smile">
                <Img fixed={logos.smile.childImageSharp.fixed} />
            </Link>
            <Link to="/ordenes/#/emerson">
                <Img fixed={logos.emerson.childImageSharp.fixed} />
            </Link>
        </Menu>
    )
}

const Ordenes = ({ location }) => {
    const store = getStoreFromHash(location.hash)

    return (
        <Layout
            seourl={location.pathname}
            seotitle="Convenience Store Mini Market Tienda de Conveniencia"
        >
            <Helmet
                htmlAttributes={{
                    class: store,
                }}
            />
            <Order.Main
                style={{
                    margin: '1rem var(--spacing-global)',
                }}
            >
                {store ? (
                    <>
                        <h1>
                            <ShowDate date={new Date()} />
                        </h1>
                        <Content store={store} />
                    </>
                ) : (
                    <SelectStore />
                )}
            </Order.Main>
        </Layout>
    )
}

export default Ordenes
